import React from 'react'
import { Link } from 'gatsby'
import './success.css'

function success() {
  return (
    <div>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.2/css/all.min.css" />
      <div className="main-5"><h2 style={{fontSize:'1.5rem',}}>THANK YOU!!</h2>
      <h3 style={{fontSize:'1rem'}}>your pre-order is successfull</h3>
      <Link style={{textDecoration:'none'}} to='/'>
      <i style={{color:'#3287a7',paddingTop:'20px',paddingLeft:'20px',fontSize:'20px'}}  class="fas fa-chevron-left"></i>
      <div style={{paddingTop:'5px'}}> <span style={{color:'#fffde5',fontSize:'0.8rem',fontStyle:'italic',textDecoration:'none',outline:'none'}}>Return</span>
     
</div>
</Link>
      <div id="back-index" className="back-player"></div>
      </div>
      
      </div>
      
    
  )
}

export default success